<template>
  <section style="margin-top: -10px">
    <el-container class="conInfo" style="padding-top: -10px">
      <h3 v-if="contractInfo" style="margin: 0; padding-bottom: 10px">
        {{ contractInfo.contract.name }}
        <span style="color: #999; font-weight: 500">{{
          contractInfo.contract.status
            ? "- " + contractInfo.contract.status
            : ""
        }}</span>
      </h3>
      <el-container v-if="contractInfo">
        <el-aside width="65%">
          <el-row class="conBox">
            <p class="conTit">基础信息</p>
            <el-form label-width="100px" label-position="right">
              <el-col :span="22">
                <p
                  style="
                    width: 100px;
                    text-align: right;
                    padding-right: 12px;
                    float: left;
                    margin-top: 0;
                  "
                >
                  客户公司名称：
                </p>
                <div
                  class="clearfix"
                  style="width: calc(100% - 180px); float: left"
                >
                  <p
                    style="margin-top: 0"
                    v-for="(i, index) in contractInfo.companyName"
                    :key="'name' + index"
                  >
                    {{ i }}
                  </p>
                </div>
              </el-col>
              <el-col :span="22">
                <el-form-item label="签约公司：">{{
                  contractInfo.signCompanyName
                }}</el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="合同有效期："
                  >{{ contractInfo.contract.startDate }} 至
                  {{
                    contractInfo.contract.endDate
                      ? contractInfo.contract.endDate
                      : "无期限"
                  }}</el-form-item
                >
              </el-col>
              <el-col :span="22">
                <el-form-item label="保证期：">{{
                  contractInfo.contract.guaranteePeriod
                }}</el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="付款周期：">{{
                  contractInfo.contract.paymentCycle
                }}</el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="收费标准：">{{
                  contractInfo.contract.feeStandard
                }}</el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="合同附件：">
                  <span
                    v-for="(item, index) in contractInfo.fileList"
                    :key="index"
                    style="
                      margin-right: 20px;
                      margin-bottom: 10px;
                      cursor: pointer;
                    "
                    ><i
                      class="iHover"
                      @click="previewOpen(item)"
                      style="color: #2b80ff; font-style: normal"
                      >{{ item.fileName }}</i
                    >
                    <i
                      @click="downFile(item)"
                      v-if="item"
                      class="el-icon-download"
                      style="margin: 0 15px"
                    ></i
                    ><i class="iconfont icondaochubiaoge"></i
                  ></span>
                </el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="备注：">{{
                  contractInfo.contract.remark
                }}</el-form-item>
              </el-col>
              <el-col :span="22">
                <el-form-item label="邮寄：">{{
                  contractInfo.contract.sendMail ? "需要" : "不需要"
                }}</el-form-item>
              </el-col>
            </el-form>
          </el-row>
          <div class="conBox">
            <p class="conTit">开票信息</p>
            <ul
              v-if="
                contractInfo.contractInvoiceList != null ||
                contractInfo.contractInvoiceList.length > 0
              "
              class="invoceListdetails"
            >
              <li
                v-for="item in contractInfo.contractInvoiceList"
                :key="item.id"
                style="border: 1px solid #efefef; border-left: 2px solid #888"
              >
                <div class="cont clearfix">
                  <el-row>
                    <el-form label-width="110px" label-position="left">
                      <el-col :span="24">
                        <el-form-item label="抬头：">{{
                          item.name
                        }}</el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="税号：">{{
                          item.taxNumber
                        }}</el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="开户银行：">{{
                          item.openingBank
                        }}</el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="银行账户：">{{
                          item.openingAccount
                        }}</el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="注册固定场地：">{{
                          item.regisAddress
                        }}</el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="注册固定电话：">{{
                          item.registTel
                        }}</el-form-item>
                      </el-col>
                    </el-form>
                  </el-row>
                </div>
              </li>
            </ul>
          </div>
          <div class="conBox">
            <p class="conTit" v-if="contractInfo.contract.number">法务信息</p>
            <el-form @submit.native.prevent v-if="contractInfo.contract.number">
              <el-row :gutter="24">
                <el-col :span="12" :offset="1">
                  <el-form-item label="合同编号：">{{
                    contractInfo.contract.number
                  }}</el-form-item>
                </el-col>
                <el-col :span="23" :offset="1">
                  <el-form-item label="合同附件(终)：">
                    <span
                      v-for="(item, index) in contractInfo.imgList"
                      :key="index"
                      style="
                        margin-right: 20px;
                        margin-bottom: 10px;
                        cursor: pointer;
                      "
                      ><i
                        class="iHover"
                        @click="previewOpen(item)"
                        style="color: #2b80ff; font-style: normal"
                        >{{ item.fileName }}</i
                      >
                      <i
                        @click="downFile(item)"
                        class="el-icon-download"
                        style="margin-left: 15px"
                      ></i>
                      <i class="iconfont icondaochubiaoge"></i
                    ></span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-aside>
        <el-main v-if="contractInfo.approvalInfo.length > 0">
          <el-card
            shadow="hover"
            style="margin-bottom: 30px"
            v-for="(i, index) in contractInfo.approvalInfo"
            :key="index"
          >
            <p
              style="
                text-align: center;
                border-bottom: 1px solid #efefef;
                padding-bottom: 5px;
                font-size: 16px;
              "
            >
              {{ i.approvalStatus }}
            </p>
            <el-timeline>
              <el-timeline-item
                v-for="(j, index) in i.contractApprovalResultStringList"
                :key="index"
                :color="
                  j.result && j.result.indexOf('审批中') != -1 ? '#ff3f3f' : ''
                "
              >
                <span style="color: #ccc; font-size: 12px">{{
                  j.approvalTime
                }}</span>
                <div>{{ j.result }}</div>
                <p
                  style="color: #ff3f3f; margin: 8px 0; font-size: 12px"
                  v-if="
                    j.remark &&
                    j.result.split('').slice(-4, -1).join('') != '已退回'
                  "
                >
                  驳回理由：{{ j.remark }}
                </p>
                <p
                  style="color: #ff3f3f; margin: 8px 0; font-size: 12px"
                  v-if="
                    j.remark &&
                    j.result.split('').slice(-4, -1).join('') == '已退回'
                  "
                >
                  退回理由：{{ j.remark }}
                </p>
                <p
                  style="
                    color: #ff3f3f;
                    word-break: break-all;
                    word-wrap: break-word;
                    margin: 8px 0;
                    font-size: 12px;
                  "
                  v-if="
                    j.suggestion &&
                    j.result.split('').slice(-4, -1).join('') != '已盖章'
                  "
                >
                  建议：{{ j.suggestion }}
                </p>
                <p
                  style="margin: 5px 0 0; color: #666; font-size: 12px"
                  v-if="j.needLowInfo"
                >
                  合同附件(终)：
                </p>
                <p style="margin: 5px 0" v-if="j.needLowInfo">
                  <span
                    v-for="(item, index) in contractInfo.imgList"
                    :key="index"
                    style="display: block; font-size: 12px; cursor: pointer"
                  >
                    <i
                      class="iHover"
                      @click="previewOpen(item)"
                      style="color: #2b80ff; font-style: normal"
                      >{{ item.fileName }}</i
                    >
                    <i
                      @click="downFile(item)"
                      class="el-icon-download"
                      style="margin-left: 15px"
                    ></i>
                    <i
                      class="iconfont icondaochubiaoge"
                      style="font-size: 12px; font-weight: bold; color: #bdbdbd"
                    ></i>
                  </span>
                </p>
                <p
                  style="
                    color: #ff3f3f;
                    word-break: break-all;
                    word-wrap: break-word;
                    font-size: 12px;
                    margin: 8px 0;
                  "
                  v-if="j.contractNumber"
                >
                  合同编号：{{ j.contractNumber }}
                </p>
                <p
                  style="color: #ff3f3f; font-size: 12px; margin: 8px 0"
                  v-if="j.files"
                >
                  附件：
                </p>
                <p v-if="j.files" style="margin: 8px 0">
                  <span
                    v-for="(item, index) in j.files"
                    :key="index"
                    style="display: block; font-size: 12px; cursor: pointer"
                    @click="downFile(item)"
                  >
                    <i
                      class="iHover"
                      style="color: #2b80ff; font-style: normal"
                      >{{ item.fileName }}</i
                    >
                    <i
                      class="iconfont icondaochubiaoge"
                      style="font-size: 12px; font-weight: bold; color: #bdbdbd"
                    ></i>
                  </span>
                </p>
              </el-timeline-item>
            </el-timeline>
          </el-card>
        </el-main>
      </el-container>
      <el-footer
        style="cursor: pointer; color: #fff; font-size: 18px"
        v-show="
          contractInfo.canCancel ||
          contractInfo.canPass ||
          contractInfo.canReject ||
          contractInfo.canEdit ||
          contractInfo.canChange ||
          contractInfo.canAddDate
        "
      >
        <div
          v-if="contractInfo.canCancel"
          @click="handleCancel(contractInfo.contractApprovalItemId)"
        >
          取消审批
        </div>
        <div v-if="contractInfo.canPass && contractInfo.canReject">
          <span @click="handlePass(contractInfo.contractApprovalItemId)"
            >通过</span
          >
          <span>|</span>
          <span @click="handleReject(contractInfo.contractApprovalItemId)"
            >驳回</span
          >
        </div>
        <div
          v-if="contractInfo.canEdit"
          @click="handleEditCheck(contractInfo.contract.id)"
        >
          调整
        </div>
        <div>
          <span
            v-if="contractInfo.canChange"
            @click="handleCanChange(contractInfo.contract.id)"
            >变更</span
          >
          <span v-if="contractInfo.canChange">&nbsp; |&nbsp;</span>
          <span
            v-if="contractInfo.canAddDate"
            @click="handleAddDate(contractInfo.contract.id)"
            >续约</span
          >
        </div>
      </el-footer>
      <el-dialog
        :width="innerWidth"
        :title="innerTitName"
        :visible.sync="innerVisible"
        append-to-body
        :modal-append-to-body="false"
        :close-on-click-modal="false"
      >
        <addInfo
          ref="saveInfo"
          v-if="iseditInfo"
          :contractId="contractId"
          :status="status"
          @changeSaveLoading="changeSaveLoading"
          @loadList="loadList"
        ></addInfo>
        <el-form
          @submit.native.prevent
          :model="createFWinfo"
          v-if="isAddFWinfo"
          ref="createFWinfo"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="合同附件(终)：" class="is-required">
                <el-upload
                  ref="upload"
                  :headers="myHeaders"
                  :action="actionUrl"
                  :before-upload="beforeUpload"
                  :on-success="handleInfoSuccess"
                  :on-error="handleInfoError"
                  :file-list="fileInfoList"
                  :on-remove="handleRemove"
                  accept=".pdf,.doc,.docx,.jpg,.png,.bmp"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">
                    支持扩展名：.pdf.doc.docx.jpg.png.bmp
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="default"
            plain
            size="mini"
            @click="cancel()"
            id="editCancel"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="save()"
            id="editSave"
            >保存</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        width="40%"
        title="编辑合同附件"
        :visible.sync="editContractVisible"
        append-to-body
        :modal-append-to-body="false"
        :close-on-click-modal="false"
      >
        <el-form
          :model="contractAccessory"
          ref="contractAccessory"
          v-if="editContractVisible"
        >
          <el-form-item prop="imgList">
            <el-upload
              class="upload-demo uploadContract"
              drag
              :headers="myHeaders"
              :action="actionUrl"
              :before-upload="beforeUpload"
              :on-success="handleContractInfoSuccess"
              :on-error="handleInfoError"
              :file-list="contractFileInfoList"
              :on-remove="handleContractRemove"
              accept=".pdf,.doc,.docx,.jpg,.png,.bmp"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <span class="tabHref">点击上传</span>
                <div
                  slot="tip"
                  style="
                    margin-top: -5px;
                    font-size: 12px;
                    line-height: 1.5;
                    color: #606266;
                  "
                >
                  支持扩展名：.pdf.doc.docx.jpg.png.bmp
                </div>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="default"
            plain
            size="mini"
            @click="editContractVisible = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="
              editContractVisible = false;
              saveContract();
            "
            >保存</el-button
          >
        </span>
      </el-dialog>
    </el-container>
  </section>
</template>

<script>
import {
  getContractandApprovalById,
  cancelContractApproval,
  passContractApproval,
  getIsLowPass, //通过时判断是否为法务
  rejectContractAndApproval,
} from "../../api/api";
import addInfo from "./addContract";
import { ApiBaseUrl, PreviewUrl } from "@/api/http";
import Cookies from "js-cookie";
export default {
  components: { addInfo },
  props: ["contractId"],
  data() {
    return {
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      baseUrl: ApiBaseUrl,
      contractInfo: "",
      iseditInfo: false,
      editContractVisible: false,
      innerVisible: false,
      saveloading: false,
      isAddFWinfo: false,
      innerWidth: "",
      innerTitName: "",
      fileInfoList: [],
      contractFileInfoList: [],
      status: "",
      createFWinfo: {
        contractapprovalitemId: "",
        number: "",
        imgList: [],
        isLowPass: false,
      },
      contractAccessory: {
        imgList: [],
      },
    };
  },
  watch: {
    contractId(newValue, oldValue) {
      this.loadDetails(newValue);
    },
  },
  methods: {
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    // 编辑合同附件
    handleContractEdit() {
      this.editContractVisible = true;
    },
    // 取消
    cancelContract() {
      this.contractFileInfoList = [];
    },
    // 确定
    saveContract() {
      this.contractFileInfoList = [];
    },
    handleContractInfoSuccess(res, file, fileList) {
      if (res.success) {
        this.contractAccessory.imgList = [];
        fileList.forEach((item) => {
          this.contractAccessory.imgList.push({
            fileName: item.name,
            filePath: item.response ? item.response.result : item.url,
          });
        });
      }
    },
    handleContractRemove(file, fileList) {
      this.contractAccessory.imgList = [];
      fileList.forEach((item) => {
        this.contractAccessory.imgList.push({
          fileName: item.name,
          filePath: item.response ? item.response.result : item.url,
        });
      });
    },
    handleInfoSuccess(res, file, fileList) {
      if (res.success) {
        this.createFWinfo.imgList = [];
        fileList.forEach((item) => {
          this.createFWinfo.imgList.push({
            fileName: item.name,
            filePath: item.response.result,
          });
        });
      }
    },
    handleInfoError(err) {
      this.$message.error("文件上传出错");
    },
    beforeUpload: function (file) {
      const isLt = file.size / 1024 / 1024 < 20;
      if (!isLt) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt;
    },
    handleRemove(file, fileList) {
      this.createFWinfo.imgList = [];
      fileList.forEach((item) => {
        this.createFWinfo.imgList.push({
          fileName: item.name,
          filePath: item.response.result,
        });
      });
    },
    cancel() {
      this.innerVisible = false;
      this.iseditInfo = false;
      this.isAddFWinfo = false;
      this.fileInfoList = [];
    },
    downFile(item) {
      location.href =
        this.baseUrl +
        "/api/file/download?fileName=" +
        encodeURIComponent(item.fileName) +
        "&filePath=" +
        item.filePath;
    },
    //预览
    previewOpen(item) {
      // let item = this.versionVal;
      const fileType =
        item.fileName.split(".")[item.fileName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xlsx" ||
        fileType == "pptx" ||
        fileType == "ppt"
      ) {
        //wps预览
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            PreviewUrl +
            item.filePath,
          "_blank"
        );
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(
          "/pdf/web/viewer.html?file=" + PreviewUrl + item.filePath,
          "_blank"
        );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        window.open(PreviewUrl + item.filePath, "_blank");
      } else if (fileType) {
        this.$message.error("不支持预览");
      }
    },
    save() {
      if (this.iseditInfo) {
        this.$refs.saveInfo.save("contract");
      }
      if (this.isAddFWinfo) {
        this.$refs["createFWinfo"].validate((valid) => {
          if (valid) {
            if (this.createFWinfo.imgList.length == 0) {
              this.$message.error("请先上传附件");
              return false;
            }
            passContractApproval(this.createFWinfo).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.$emit("loadList");
                this.innerVisible = false;
                this.resetForm("createFWinfo");
                this.fileInfoList = [];
              }
            });
          }
        });
      }
    },
    loadList() {
      this.$emit("loadList");
    },
    loadDetails(rid) {
      getContractandApprovalById({ contractId: rid, option: "创建" }).then(
        (res) => {
          if (res.success) {
            this.contractInfo = res.result;
            this.contractFileInfoList = [];
            if (
              this.contractInfo.imgList &&
              this.contractInfo.imgList.length > 0
            ) {
              this.contractInfo.imgList.forEach((item) => {
                this.contractFileInfoList.push({
                  name: item.fileName,
                  url: item.filePath,
                });
              });
            }
          }
        }
      );
    },
    handleCancel(id) {
      this.$confirm("请确认是否取消审批？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          cancelContractApproval({ contractapprovalitemId: id }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.$emit("loadList");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handlePass(id) {
      this.$confirm("请确认是否通过审批？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          this.createFWinfo.contractapprovalitemId = id;
          getIsLowPass({ contractapprovalitemId: id }).then((res) => {
            if (res.success) {
              this.createFWinfo.isLowPass = res.result;
              if (res.result) {
                //法务审批
                this.innerVisible = true;
                this.innerWidth = "40%";
                this.innerTitName = "编辑法务信息";
                this.isAddFWinfo = true;
              } else {
                passContractApproval(this.createFWinfo).then((res1) => {
                  if (res1.success) {
                    this.$message.success(res1.message);
                    this.$emit("loadList");
                  }
                });
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleReject(id) {
      this.$prompt("驳回理由", "驳回操作", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPlaceholder: "请输入驳回理由",
        inputPattern: /\S/,
        inputErrorMessage: "请输入驳回理由",
      })
        .then(({ value }) => {
          rejectContractAndApproval({
            contractapprovalitemId: id,
            reason: value,
          }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.$emit("loadList");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    handleEditCheck(id) {
      this.innerVisible = true;
      this.iseditInfo = true;
      this.contractId = id;
      this.innerWidth = "60%";
      this.innerTitName = "编辑合同信息";
      this.status = 0;
    },
    handleCanChange(id) {
      this.innerVisible = true;
      this.iseditInfo = true;
      this.contractId = id;
      this.innerWidth = "60%";
      this.innerTitName = "变更合同信息";
      this.status = 3;
    },
    handleAddDate(id) {
      this.innerVisible = true;
      this.iseditInfo = true;
      this.contractId = id;
      this.innerWidth = "60%";
      this.innerTitName = "编辑合同信息";
      this.status = 6;
    },
    handleClose(done) {
      done();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    this.loadDetails(this.contractId);
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
  margin-top: 10px;
}
.conInfo {
  .el-aside {
    height: 500px;
    overflow-y: scroll;
    margin-right: 10px;
    // border: 1px solid #efefef;
    // box-shadow: 0 0 5px #ccc;
    .conBox {
      border: 1px solid #ebebeb;
      padding: 0 20px;
      margin-bottom: 10px;
    }
    .el-col-22 {
      padding: 0;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .conTit {
      // background-color: #ff3f3f;
      color: #333;
      padding-bottom: 5px;
      border-bottom: 1px solid #ebebeb;
      // span{
      // padding-left: 10px;
      // font-size: 16px;
      // line-height: 1;
      // border-left: 2px solid #ff3f3f;
      // }
    }
    .invoceListdetails {
      padding-inline-start: 0;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 20px;
      li {
        padding: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dotted #ebebeb;
        .tit {
          text-align: center;
          position: relative;
          &::after {
            content: "";
            width: 100px;
            height: 2px;
            background-color: #ff3f3f;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 50px);
          }
        }
        .cont {
          p {
            width: 100%;
            float: left;
            margin: 0;
            padding-bottom: 10px;
            padding-left: 10px;
            margin-right: 2%;
            text-align: left;
            &:nth-child(3n) {
              margin-right: 0;
            }
            span {
              width: 100px;
              color: #999;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .el-main {
    height: 500px;
    background-color: #fff;
    padding: 0;
    // border: 1px solid #efefef;
    // box-shadow: 0 0 5px #ccc;
    .el-timeline {
      padding-inline-start: 0;
    }
  }
}
</style>
<style lang="scss">
.uploadContract {
  width: 100%;
  .el-upload.el-upload--text {
    width: 100%;
    line-height: 1;
    .tips {
      margin-top: 10px;
    }
    .el-upload-dragger {
      width: 100%;
    }
  }
}
.iHover {
  overflow-wrap: break-word;
  max-width: 100%;
  &:hover {
    border-bottom: 1px solid rgb(43, 128, 255) !important;
  }
}
</style>
<style scoped>
.el-form :deep(p) {
  margin-bottom: 0;
}
.el-col-22 :deep(.el-form-item__content),
.el-col-22 :deep(.el-form-item__label) {
  line-height: 30px;
}
.el-timeline :deep(.el-timeline-item) {
  padding-bottom: 5px;
}
.el-dialog :deep(.el-dialog__body) {
  padding: 20px !important;
}
.el-main :deep(.el-card__body) {
  padding: 0 20px 10px 10px;
}
</style>
