<template>
  <div class="wrapper contracts">
    <el-row class="main">
      <aside class="menu-expanded" v-show="!collapsed">
        <!--导航菜单-->
        <div class="menu">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="" name="1">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-files"></el-icon>快捷菜单
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.val, 'quick')"
                  v-for="item in menuList"
                  :class="actived == item.val ? 'actived' : ''"
                  :key="item.val"
                >
                  {{ item.name }}
                </li>
              </ul>
            </el-collapse-item>
            <el-collapse-item title="" name="2">
              <template slot="title"
                ><div class="subTit">
                  <el-icon class="el-icon-collection-tag"></el-icon>订阅搜索
                </div></template
              >
              <ul class="menuList">
                <li
                  @click="searchCus(item.id, 'order')"
                  v-for="item in OrderSearchList"
                  :class="actived == item.id ? 'actived' : ''"
                  :key="item.id"
                >
                  {{ item.name }}
                  <div class="closed" @click.stop="delSearch(item.id)">
                    &times;
                  </div>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
      </aside>
      <section
        class="content-container clearfix scollDom"
        :class="collapsed ? 'screen' : ''"
        id="contracts"
      >
        <el-col :span="24">
          <el-row class="searchBox">
            <el-form @submit.native.prevent :model="filter" ref="filter">
              <div class="fl searchL">
                <el-form-item label style="margin-bottom: 20px">
                  <el-input
                    class="search"
                    v-model="filter.filter"
                    @keyup.enter.native="submitForm('filter')"
                    placeholder="请输入客户名称 / 合同名称 / 合同编号 / 创建人，支持模糊搜索"
                  >
                    <el-button
                      type="primary"
                      slot="append"
                      @click="submitForm('filter')"
                      >搜 索</el-button
                    >
                  </el-input>
                </el-form-item>
              </div>
              <div class="other">
                <span class="order" @click="orderSearch">订阅搜索</span>
                <span class="more" @click="showMore = !showMore"
                  >{{ showMore ? "收起搜索" : "展开搜索"
                  }}<el-icon
                    :class="
                      showMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                    "
                  ></el-icon>
                </span>
              </div>
            </el-form>
            <div class="searchM" v-show="showMore">
              <el-form
                @submit.native.prevent
                :model="filter"
                ref="filter"
                class="clearfix"
                label-width="85px"
              >
                <el-form-item label="客户：" prop="companyName">
                  <el-input
                    placeholder="请输入客户"
                    v-model="filter.companyName"
                    class="searchInput numberHeight"
                    style="width: 240px"
                  >
                    <template slot="suffix">
                      <el-icon
                        class="el-icon-search"
                        @click.native="submitForm('filter')"
                      ></el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="合同编号：" prop="contractNumber">
                  <el-input
                    placeholder="请输入合同编号"
                    v-model="filter.contractNumber"
                    class="searchInput numberHeight"
                    style="width: 240px"
                  >
                    <template slot="suffix">
                      <el-icon
                        class="el-icon-search"
                        @click.native="submitForm('filter')"
                      ></el-icon>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="创建人：" prop="createdUserIds">
                  <el-select
                    v-model="filter.createdUserIds"
                    placeholder="请选择"
                    style="width: 240px"
                    filterable
                    multiple
                    clearable
                    @change="submitForm('filter')"
                    :disabled="filter.ownProject"
                    :filter-method="filterFun"
                  >
                    <el-option
                      v-for="i in userList"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="合同状态：" prop="contractStatus">
                  <el-select
                    v-model="filter.contractStatus"
                    placeholder="请选择"
                    style="width: 240px"
                    multiple
                    clearable
                    collapse-tags
                    @change="submitForm('filter')"
                  >
                    <el-option
                      v-for="(item, index) in projectType"
                      :key="'a_' + index"
                      :value="item.value"
                      :label="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <el-col class="table-top">
              <el-button size="mini" @click="addNew" id="addNewContracts">
                新增合同</el-button
              >
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                style="padding: 0"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
              ></el-pagination>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="pd40" style="padding-bottom: 10px">
            <el-col :span="24">
              <el-table
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                  height: '35px',
                  'border-top': '1px #e5e5e5 solid',
                }"
                :data="ListData"
                v-horizontal-scroll
                v-sticky="{ top: 0, parent: '.scollDom' }"
                class="tabBorder custor"
                v-loading="listLoading"
                ref="tableList"
                style="border-top: 0"
              >
                <el-table-column prop="id" label="审批ID" width="100">
                </el-table-column>
                <el-table-column prop="status" label="合同状态" width="130">
                  <template slot-scope="scope">
                    <span>{{ scope.row.status }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="合同名称"
                  width="250"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column prop="number" label="合同编号" width="120">
                  <template slot-scope="scope">
                    <span>{{ scope.row.number ? scope.row.number : "-" }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="company" label="客户" width="300">
                  <template slot-scope="scope">
                    <p
                      class="tabHref text-overflows"
                      v-for="i in scope.row.company"
                      :key="i.id"
                      @click="toCustomer(i.id)"
                      :title="i.companyName"
                    >
                      {{ i.companyName }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="endDate" label="有效期" width="210">
                  <template slot-scope="scope">
                    <span
                      >{{
                        scope.row.startDate
                          ? scope.row.startDate + " 至 "
                          : scope.row.startDate
                      }}{{
                        scope.row.endDate ? scope.row.endDate : "无期限"
                      }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="signCompanyName"
                  :show-overflow-tooltip="true"
                  width="230"
                  label="签约公司"
                >
                </el-table-column>
                <el-table-column
                  prop="creatorUserName"
                  label="创建人"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="creationTime"
                  label="创建时间"
                  width="160"
                >
                </el-table-column>
                <el-table-column label="操作" width="100" fixed="right">
                  <template #default="scope">
                    <span
                      class="tabHref"
                      :id="'contractsApprovalViewLook' + scope.$index"
                      @click="handleEdit(scope.row.id)"
                      >查看</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                :hide-on-single-page="filter.page.total > 10 ? false : true"
                v-if="filter.page.total > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="filter.page.current"
                :page-size="filter.page.size"
                :pager-count="5"
                layout="total,prev, pager, next,sizes"
                :total="filter.page.total"
                :page-sizes="[10, 20, 50, 100]"
                style="margin-bottom: 10px"
              ></el-pagination>
            </el-col>
          </el-row>
        </el-col>
      </section>
    </el-row>
    <!-- 订阅搜索弹框 -->
    <el-dialog
      :visible.sync="orderSearchVisible"
      :modal-append-to-body="false"
      append-to-body
      title="订阅搜索"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form
        @submit.native.prevent
        :model="searchFrom"
        ref="searchFrom"
        style="padding: 0 50px"
        :rules="rules"
        :validate-on-rule-change="false"
        label-width="0"
        label-position="right"
      >
        <el-form-item prop="name">
          <el-input
            placeholder="请给订阅搜索取个名字"
            v-model.trim="searchFrom.name"
            maxlength="12"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            resetForm('searchFrom');
            orderSearchVisible = false;
          "
          size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="isSubscribeSearch"
          @click="submitOrder('searchFrom')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 新增合同、查看合同弹窗 -->
    <el-drawer
      :visible.sync="dialogVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i
          v-if="titName == '合同详情'"
          class="drawIcon iconfont icon-xm-xq"
        ></i>
        <i
          v-if="titName == '新增合同信息'"
          class="drawIcon iconfont icon-ht"
        ></i>
        {{ titName }}
      </div>
      <div class="drawerCon">
        <addInfo
          ref="saveInfo"
          v-if="isaddInfo"
          @changeSaveLoading="changeSaveLoading"
          @loadList="loadList()"
          entry="合同管理"
        ></addInfo>
        <div class="drawerCon_footer fixedFoot" v-if="isaddInfo">
          <el-button
            type="default"
            plain
            size="mini"
            @click="(dialogVisible = false), cancel()"
            id="addCancel"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 15px"
            :loading="saveloading"
            @click="save()"
            id="addSave"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 查看 -->
    <el-drawer
      :visible.sync="isCheckInfo"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i v-if="titName == '合同详情'" class="drawIcon iconfont icon-ht"></i
        >{{ titName }}
      </div>
      <div class="drawerCon">
        <contractInfo
          v-if="isCheckInfo"
          :contractId="contractId"
          :activeName="'创建'"
          @loadList="loadList"
        ></contractInfo>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="customerDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>客户
      </div>
      <div class="drawerCon">
        <customer
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customer>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  SearchAddOrEdit, //新增-编辑订阅管理
  GetSearchPage, //获取订阅管理列表
  GetSearchById, //根据ID获取方案
  deleteSearchById, //通过id删除方案
  getUserBaseInfos, //获取创建人列表
  getContractList, //获取合同管理列表
  getIsSettingApproval, //查询配置状态
} from "../../api/api";
import {
  statisticsContractAdd, //点击新增合同
} from "../../api/buryingPoint";
import { mapGetters } from "vuex";
import * as data from "../../common/json/commData.json";
import addInfo from "../../components/contract/addContract";
import checkContract from "../../components/contract/checkContract";
import contractInfo from "../../components/contract/contractInfo";
import customer from "../../components/customer/customerInfo";
export default {
  name: "contracts",
  components: { addInfo, checkContract, contractInfo, customer },
  data() {
    return {
      activeNames: ["1", "2"],
      actived: "我发起的合同",
      menuList: [
        {
          name: "我发起的合同",
          val: "我发起的合同",
        },
        {
          name: "全部合同",
          val: "全部合同",
        },
      ],
      OrderSearchList: [],
      orderSearchVisible: false,
      //表单筛选
      filter: {
        filter: "",
        companyName: "",
        contractNumber: "",
        contractStatus: [],
        createdUserIds: [],
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: true,
              column: "",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      },
      showMore: false,
      projectType: data.selectContractStatus, //任务类型
      userList: [],
      userListCopy: [],
      searchFrom: {
        id: null,
        name: "",
        searchList: {},
        type: "合同管理",
        version: 0,
      },
      rules: {
        name: [
          { required: true, trigger: "blur", message: "请给订阅搜索取个名字" },
        ],
      },
      ListData: [],
      listLoading: false,
      titName: "",
      isaddInfo: false, //新增合同
      dialogVisible: false,
      dialogWidth: "",
      saveloading: false,
      isCheckInfo: false, //查看
      isSubscribeSearch: false,
      activedToInfo: "1",
      customerDrawerVisible: false,
      customId: "",
    };
  },
  destroyed() {
    this.canScroll();
  },
  mounted() {
    this.noScroll(); //禁止滚动
  },
  created() {
    if (!this.$route.query.order && !this.$route.query.folder) {
      if (this.$route.query.ownProject) {
        //带参刷新
        this.filter.ownProject = eval(this.$route.query.ownProject);
        if (this.$route.query.status !== "全部合同") {
          var userInfo = JSON.parse(this.getStore("useInfo"));
          this.filter.createdUserIds.push(userInfo.id);
          this.filter.scheduleStatus = this.$route.query.status;
        }
        this.actived = this.$route.query.status;
      } else {
        //初始默认
        this.filter.ownProject = true;
        this.filter.scheduleStatus = "";
        var userInfo = JSON.parse(this.getStore("useInfo"));
        this.filter.createdUserIds.push(userInfo.id);
      }
    } else {
      this.actived = this.$route.query.order || this.$route.query.folder;
      if (this.$route.query.order) {
        //订阅搜索
        GetSearchById(this.actived).then((res) => {
          if (res.success) {
            this.filter = res.result.searchList;
          }
        });
      }
      if (this.$route.query.folder) {
        //文件夹
        this.filter.favoriteId = this.actived;
        this.delFiles.favoriteId = this.actived;
      }
    }
    this.loadOrderSearchList();
    this.loadList();
    this.loadUserBase();
    if (this.customerId) {
      this.calendardata.companyId = this.customerId;
      this.loadContactsLitst(this.customerId, "sign");
    }
    if (this.$route.query.objectId) {
      //显示合同详情
      this.titName = "查看合同信息";
      this.contractId = this.$route.query.objectId;
      this.isCheckInfo = true;
    }
  },
  watch: {
    "project.companyIndustryIds"(newValue, oldValue) {
      if (newValue.length > 5) {
        this.$message({
          message: "最多不超过5个",
          duration: 1500,
          type: "error",
        });
        this.$nextTick(() => {
          this.project.companyIndustryIds = oldValue;
        });
      }
    },
    "$route.query"(newValue, oldValue) {
      if (!newValue.order && !newValue.folder) {
        if (newValue.ownProject) {
          this.filter.ownProject = eval(newValue.ownProject);
          this.actived = newValue.status;
          if (newValue.status !== "全部合同") {
            var userInfo = JSON.parse(this.getStore("useInfo"));
            this.filter.createdUserIds.push(userInfo.id);
            this.filter.scheduleStatus = newValue.status;
          }
        } else {
          this.actived = "我发起的合同";
          this.filter.ownProject = true;
          this.filter.scheduleStatus = null;
        }
        this.loadList();
      } else {
        //订阅&&文件夹
        this.actived = newValue.order || newValue.folder;
        if (newValue.order) {
          GetSearchById(this.actived).then((res) => {
            if (res.success) {
              this.filter = res.result.searchList;
              this.loadList();
            }
          });
        }
        if (newValue.folder) {
          //文件夹
          this.filter.favoriteId = this.actived;
          this.delFiles.favoriteId = this.actived;
          this.loadList();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      collapsed: "upDateCollapsed",
    }),
  },
  methods: {
    //导航菜单切换
    handleChange() {},
    //侧边栏切换
    searchCus(val, type) {
      if (this.actived != val) {
        this.actived = val;
        this.sortType = "";
        this.sortCheck = null;
        switch (type) {
          case "quick": //快捷菜单
            this.reset();
            this.$router.push({
              path: "/contracts",
              query: {
                ownProject: val != "全部合同" ? true : false,
                status: val,
              },
            });
            break;
          case "order":
            this.$router.push({
              path: "/contracts",
              query: {
                order: val,
              },
            });
            break;
          case "folder":
            this.$router.push({
              path: "/contracts",
              query: {
                folder: val,
              },
            });

            break;
        }
      }
    },
    //删除订阅搜索
    delSearch(id) {
      this.$confirm("确定删除该订阅?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          deleteSearchById(id).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadOrderSearchList();
              if (this.actived == id) {
                this.reset();
                this.$router.push({
                  path: "/contracts",
                  query: {
                    ownProject: true,
                    status: "我发起的合同",
                  },
                });
                this.loadList();
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //订阅搜索
    orderSearch() {
      this.orderSearchVisible = true;
    },
    //获取订阅列表
    loadOrderSearchList() {
      GetSearchPage({ type: "合同管理" }).then((res) => {
        if (res.success) {
          this.OrderSearchList = res.result;
        }
      });
    },
    //切换页数
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.loadList();
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadList();
    },
    //清空
    reset() {
      this.filter = {
        filter: "",
        companyName: "",
        contractNumber: "",
        contractStatus: [],
        createdUserIds: [],
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: true,
              column: "",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      };
    },
    // 获取合同列表
    loadList() {
      $("#contracts").scrollTop(0);
      this.listLoading = true;
      this.dialogVisible = false;
      this.isCheckInfo = false;
      getContractList(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          if (this.filter.favoriteId && this.ListData.length) {
            this.isShowDelBtn = true;
          }
          this.ListData.forEach((element) => {
            element["show"] = false;
          });
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //获取创建人列表
    loadUserBase() {
      getUserBaseInfos(this.filter).then((res) => {
        if (res.success) {
          this.userList = res.result;
          this.userListCopy = res.result;
        }
      });
    },
    //表单搜索
    submitForm() {
      if (this.filter.id && this.filter.id < 1) {
        this.$message.error("请输入正确的id");
        return false;
      }
      if (this.rangTime && this.rangTime.length > 0) {
        this.filter.scheduleStartTime = this.rangTime[0];
        this.filter.scheduleEndTime = this.rangTime[1];
      } else {
        this.filter.scheduleStartTime = "";
        this.filter.scheduleEndTime = "";
      }
      this.filter.page.current = 1;
      this.loadList();
    },
    //新增合同
    addNew() {
      statisticsContractAdd({ entry: "合同管理" }).then();
      getIsSettingApproval().then((res) => {
        if (res.success) {
          if (res.result) {
            this.titName = "新增合同信息";
            this.dialogVisible = true;
            this.dialogWidth = "70%";
            this.isaddInfo = true;
          } else {
            this.$alert(
              "你的账户角色尚未配置合同审批流程，无法创建合同，请联系系统管理员配置",
              "提示",
              {
                confirmButtonText: "确定",
                customClass: "altCls",
                callback: (action) => {},
              }
            );
          }
        }
      });
    },
    //取消新增
    cancel() {
      this.isaddInfo = false;
      this.isCheckInfo = false;
      this.customerDrawerVisible = false;
    },
    //保存新增
    save() {
      this.$refs.saveInfo.save("contract");
    },
    //关闭弹窗
    handleClose(done) {
      done();
      this.cancel();
    },
    //保存修改
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //提交订阅
    submitOrder(formName) {
      this.isSubscribeSearch = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.searchFrom.searchList = this.filter;
          SearchAddOrEdit(this.searchFrom).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.orderSearchVisible = false;
              this.loadOrderSearchList();
              this.resetForm("searchFrom");
              this.isSubscribeSearch = false;
            }
          });
        } else {
          this.isSubscribeSearch = false;
          return false;
        }
      });
    },
    //客户详情
    toCustomer(comId) {
      this.customerDrawerVisible = true;
      this.customId = comId;
    },
    //查看
    handleEdit(rid) {
      this.titName = "合同详情";
      this.contractId = rid;
      this.isCheckInfo = true;
    },
    filterFun(data) {
      if (data) {
        this.userList = this.userListCopy.filter((item) => {
          if (
            !!~item.name.indexOf(data) ||
            !!~item.name.toLowerCase().indexOf(data.toLowerCase())
          ) {
            return true;
          }
        });
      } else {
        this.userList = this.userListCopy;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../collapsed.less";
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
  }
}
.searchL {
  width: calc(100% - 162px);
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }
  .order {
    padding: 0 8px;
    border-right: 1px solid #e5e5e5;
  }
  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
</style>

<style lang='scss'>
.contracts {
  .el-table .cell {
    padding: 5px 15px;
  }
}
</style>